import { Checkbox, Tooltip, Typography, TextField, FormHelperText, Grid } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { CircularProgress } from "@material-ui/core";
import { Spinner } from "../Spinner";
import { Select, DatePicker, NewDatePicker } from "..";
import "./DetailsBox.css";
// import uploadFile from "/uploadFile";
import { CALLIBRATION_GROUP_ID } from "../../../constants";
import { uploadFile } from "utils/FileAPI";
import { useContext } from "react";
import { ErrorContext } from "../../../contexts/misc/context";
import { MarkIcon } from "assets";
import { parse } from "date-fns";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  boxContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    margin: "10px",
  },
}));

const DEFAULT_DIMS = { xs: 12 };

export const DetailsBox = ({
  formList,
  rootStyles,
  title,
  inputContainerStyles,
  titleRight,
  viewOnly = false,
  renderRight,
  renderHeader,
  renderFooter,
  showForm = false,
  setShowForm,
  isCollapsed,
}) => {
  const { setErrors } = useContext(ErrorContext);
  const classes = useStyles();
  const [dateError, setDateError] = useState("");
  const [uploadingContent, setUploadingContent] = useState(false);

  const handleUpload = async (file, formVal) => {
    setUploadingContent(true);
    try {
      const res = await uploadFile(file, CALLIBRATION_GROUP_ID);
      if (res.data.status === "success") {
        formVal.setValue(file.name, res.data.upload_id);
      } else {
        setErrors(res.data.message);
      }
    } catch (e) {
      setErrors("Error occured in uploading File");
    }
    setUploadingContent(false);
  };

  const renderInputType = (formVal) => {
    switch (formVal.inputType) {
      case "input":
        return (
          <>
            <input
              type={formVal.type || "text"}
              // inputMode={formVal.type === "number" ? "decimal" : undefined}
              step={formVal.step || (formVal.type === "number" ? "any" : undefined)}
              onChange={formVal.setValue}
              disabled={formVal.disabled}
              value={formVal.value ? formVal.value : ""}
              name={formVal.name}
              ref={formVal.ref}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`db__input ${isCollapsed
                ? formVal.name === "slope" ||
                  formVal.name === "offset" ||
                  formVal.name === "offset" ||
                  formVal.name === "uncertainty_k_factor" ||
                  formVal.name === "place_of_calibration"
                  ? formVal.inputStyles
                  : ""
                : formVal.name === "slope" ||
                  formVal.name === "offset" ||
                  formVal.name === "offset" ||
                  formVal.name === "uncertainty_k_factor" ||
                  formVal.name === "place_of_calibration"
                  ? "ca__input-box"
                  : ""
                }     ${formVal.error ? "db__input-error" : ""}`}
              autoComplete="off"
              min={formVal.min}
              max={formVal.max}
              required={formVal.required ? formVal.required : false}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "customNum":
        return (
          <>
            <input
              type={formVal.type || "text"}
              // inputMode={formVal.type === "number" ? "decimal" : undefined}
              step={formVal.step || (formVal.type === "number" ? "any" : undefined)}
              onChange={(e) => {
                let matchString = "";
                // console.log(e.target.value);
                if (formVal.numType === "decimal") matchString = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                else if (formVal.numType === "number") matchString = /^-?\d+$/;
                if (!matchString.test(e.target.value) && e.target.value.length !== 0 && e.target.value !== "-") {
                  return;
                } else formVal.setValue(e);
              }}
              disabled={formVal.disabled}
              value={formVal.value ? formVal.value : ""}
              name={formVal.name}
              ref={formVal.ref}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`db__input ${isCollapsed
                ? formVal.name === "slope" ||
                  formVal.name === "offset" ||
                  formVal.name === "offset" ||
                  formVal.name === "uncertainty_k_factor" ||
                  formVal.name === "place_of_calibration"
                  ? formVal.inputStyles
                  : ""
                : formVal.name === "slope" ||
                  formVal.name === "offset" ||
                  formVal.name === "offset" ||
                  formVal.name === "uncertainty_k_factor" ||
                  formVal.name === "place_of_calibration"
                  ? "ca__input-box"
                  : ""
                }     ${formVal.error ? "db__input-error" : ""}`}

              autoComplete="off"
              min={formVal.min}
              max={formVal.max}
              required={formVal.required ? formVal.required : false}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "textarea":
        return (
          <>
            <textarea
              type={formVal.type || "text"}
              onChange={formVal.setValue}
              value={formVal.value ? formVal.value : ""}
              disabled={formVal.disabled}
              ref={formVal.ref}
              id={formVal.id ? formVal.id : ""}
              name={formVal.name}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`  ${formVal.error ? "db__input-error" : ""} ${!isCollapsed ? "db__input2" : "db__input"}`}
              autoComplete="off"
              style={{ width: "100%" }}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "select":
        return (
          <Select
            selectedValue={formVal.value ? formVal.value : ""}
            options={formVal.options}
            name={formVal.name}
            disabled={formVal.disabled}
            onBlur={() => formVal.clickHandler && formVal.clickHandler()}
            onItemClick={(val, valObj) => formVal.setValue(val, valObj)}
            error={formVal.error}
            selectListStyles={formVal.selectListStyles}
            tdLogSelectStyles={formVal.tdLogSelectStyles}
            tdLogSelectIconStyles={formVal.tdLogSelectIconStyles}

          // getLeftIcon={formItem.getLeftIcon}
          />
        );

      case "date":
        return (
          <>
            <DatePicker
              onChange={(newDate) => {
                formVal.setValue(newDate);
              }}
              date={formVal.value ? formVal.value : null}
              // value={formVal.value ? formVal.value : null}
              emptyLabel="custom label"
              error={formVal.error}
              rootStyle={`${isCollapsed ? "sca__cal-input" : "sca__cal-input2"}`}
              pickerStyles={formVal.pickerStyles}
              disabled={formVal.disabled}
              setFieldError={formVal.setFieldError}
              setFieldTouched={formVal.setFieldTouched}
              name={formVal.name}
              logDateInputStyles={formVal.logDateInputStyles}
              maxDate={formVal.maxDate ? formVal.maxDate : undefined}
              minDate={formVal.minDate ? formVal.minDate : undefined}
              required={formVal.required}
              dateInit={formVal.dateInit}
              handleGlobalError={setDateError}
            />

            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "upload":
        return (
          <>
            <Dropzone maxFiles={1} onDrop={(files) => handleUpload(files[0], formVal)}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className={"container"}
                  style={{
                    padding: uploadingContent && !formVal.value ? "6.5px" : "0px",
                    display: "flex",
                    flex: 1,
                    border: formVal.error ? "1px solid red" : 0,
                    paddingLeft: "0",
                  }}
                >
                  <div
                    {...getRootProps({
                      className: "dropbox",
                      onDrop: (event) => event.stopPropagation(),
                    })}
                    style={{ display: "flex", flex: 1 }}
                  >
                    <input
                      {...getInputProps()}
                      disabled={formVal.disabled}
                      style={{ margin: "10px" }}
                    // onChange={(e) => handleUpload(e.target.files[0], formVal)}
                    />

                    <div className={formVal.value ? "db__upload bor" : "db__upload"}>
                      {!uploadingContent && <p>{formVal.value ? formVal.value : `Upload File`}</p>}
                      {uploadingContent && (
                        <div className={"progress"} style={{ position: "absolute" }}>
                          <CircularProgress
                            style={{
                              width: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </>
        );

      case "checkbox":
        return (
          <Checkbox
            color={"secondary"}
            disabled={formVal.disabled}
            onChange={(e) => {
              formVal.setValue(e.target.checked);
            }}
            checked={formVal.value ? formVal.value : false}
          />
        );
      default:
        return null;
    }
  };

  if (viewOnly) {
    return (
      <section className={`db_section ${rootStyles}`}>
        <article>
          <header>
            <Typography variant="h1">{title}</Typography>
            {titleRight}
          </header>

          <div className={`db__formInput ${inputContainerStyles}`}>
            {formList.map((formVal) => (
              <div className={`db__input-wrap ${formVal.inputStyles}`} key={formVal.label}>
                <Typography variant="subtitle1" style={{ marginBottom: "6px" }}>
                  {formVal.label}
                </Typography>

                <div className={`db__input-inner ${formVal.inputGreyStyles}`}>
                  <input
                    className="db__input-view"
                    type={formVal.type || "text"}
                    value={formVal.value}
                    title={formVal.value}
                    readOnly
                  />
                </div>
              </div>
            ))}
          </div>
        </article>
      </section>
    );
  }

  return (
    <section className={`db_section ${rootStyles}`}>
      <article>
        {renderHeader ? (
          renderHeader
        ) : (
          <header>
            <Typography variant="h1">{title}</Typography>
            {titleRight}
          </header>
        )}

        <div className={`db__formInput ${inputContainerStyles}`}>
          {formList.map((formVal, index) =>
            formVal.type === "divider" ? (
              <div style={{ width: "100%", height: "1px", background: "#eee", marginBlock: "22px" }} key={index}></div>
            ) : (
              <div
                className={`db__input-wrap ${formVal.inputStyles}`}
                key={index}
                style={{ width: `${formVal.fullWidth ? "100%" : "47%"}` }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Tooltip title={formVal.label || ""} placement="top" arrow>
                    <Typography variant="subtitle1" style={{ marginBottom: "6px" }}>
                      {formVal.label}
                    </Typography>
                  </Tooltip>
                  {formVal.showInfo && (
                    <Tooltip
                      title={formVal.info}
                      placement="right"
                      arrow
                      classes={{
                        tooltip: "db__input-wrap-edit-label",
                        arrow: "db__input-wrap-edit-label",
                      }}
                    >
                      <MarkIcon style={{ marginLeft: "5px", marginTop: "1px" }} />
                    </Tooltip>
                  )}
                </div>
                <div className="db__input-inner">
                  {/* <div style={{ flexdirection: "column" }}> */}
                  {renderInputType(formVal)}
                  {/* </div> */}
                  {formVal.renderRight}
                </div>
              </div>
            )
          )}
          {renderRight}
        </div>
      </article>
      {renderFooter}
    </section>
  );
};

export const DetailsBoxWithGrid = ({
  formList,
  rootStyles,
  title,
  inputContainerStyles,
  titleRight,
  viewOnly = false,
  renderRight,
  renderHeader,
  renderFooter,
  showForm = false,
  setShowForm,
  isCollapsed,
}) => {
  const { setErrors } = useContext(ErrorContext);
  const classes = useStyles();
  const [dateError, setDateError] = useState("");
  const [uploadingContent, setUploadingContent] = useState(false);

  const handleUpload = async (file, formVal) => {
    setUploadingContent(true);
    try {
      const res = await uploadFile(file, CALLIBRATION_GROUP_ID);
      if (res.data.status === "success") {
        formVal.setValue(file.name, res.data.upload_id);
      } else {
        setErrors(res.data.message);
      }
    } catch (e) {
      setErrors("Error occured in uploading File");
    }
    setUploadingContent(false);
  };

  const renderInputType = (formVal) => {
    switch (formVal.inputType) {
      case "input":
        return (
          <>
            <input
              type={formVal.type || "text"}
              // inputMode={formVal.type === "number" ? "decimal" : undefined}
              step={formVal.step || (formVal.type === "number" ? "any" : undefined)}
              onChange={formVal.setValue}
              disabled={formVal.disabled}
              value={formVal.value ? formVal.value : ""}
              name={formVal.name}
              ref={formVal.ref}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`db__input ${
                isCollapsed
                  ? formVal.name === "slope" ||
                    formVal.name === "offset" ||
                    formVal.name === "offset" ||
                    formVal.name === "uncertainty_k_factor" ||
                    formVal.name === "place_of_calibration"
                    ? formVal.inputStyles
                    : ""
                  : formVal.name === "slope" ||
                    formVal.name === "offset" ||
                    formVal.name === "offset" ||
                    formVal.name === "uncertainty_k_factor" ||
                    formVal.name === "place_of_calibration"
                  ? "ca__input-box"
                  : ""
              }     ${formVal.error ? "db__input-error" : ""}`}
              autoComplete="off"
              min={formVal.min}
              max={formVal.max}
              required={formVal.required ? formVal.required : false}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "customNum":
        return (
          <>
            <input
              type={formVal.type || "text"}
              // inputMode={formVal.type === "number" ? "decimal" : undefined}
              step={formVal.step || (formVal.type === "number" ? "any" : undefined)}
              onChange={(e) => {
                let matchString = "";
                // console.log(e.target.value);
                if (formVal.numType === "decimal") matchString = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                else if (formVal.numType === "number") matchString = /^-?\d+$/;
                if (!matchString.test(e.target.value) && e.target.value.length !== 0 && e.target.value !== "-") {
                  return;
                } else formVal.setValue(e);
              }}
              disabled={formVal.disabled}
              value={formVal.value ? formVal.value : ""}
              name={formVal.name}
              ref={formVal.ref}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`db__input ${
                isCollapsed
                  ? formVal.name === "slope" ||
                    formVal.name === "offset" ||
                    formVal.name === "offset" ||
                    formVal.name === "uncertainty_k_factor" ||
                    formVal.name === "place_of_calibration"
                    ? formVal.inputStyles
                    : ""
                  : formVal.name === "slope" ||
                    formVal.name === "offset" ||
                    formVal.name === "offset" ||
                    formVal.name === "uncertainty_k_factor" ||
                    formVal.name === "place_of_calibration"
                  ? "ca__input-box"
                  : ""
              }     ${formVal.error ? "db__input-error" : ""}`}
              autoComplete="off"
              min={formVal.min}
              max={formVal.max}
              required={formVal.required ? formVal.required : false}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "textarea":
        return (
          <>
            <textarea
              type={formVal.type || "text"}
              onChange={formVal.setValue}
              value={formVal.value ? formVal.value : ""}
              disabled={formVal.disabled}
              ref={formVal.ref}
              id={formVal.id ? formVal.id : ""}
              name={formVal.name}
              placeholder={formVal.placeholder}
              onBlur={formVal.clickHandler}
              className={`  ${formVal.error ? "db__input-error" : ""} ${!isCollapsed ? "db__input2" : "db__input"}`}
              autoComplete="off"
              style={{ width: "100%" }}
            />
            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "select":
        return (
          <Select
            selectedValue={formVal.value ? formVal.value : ""}
            options={formVal.options}
            name={formVal.name}
            disabled={formVal.disabled}
            onBlur={() => formVal.clickHandler && formVal.clickHandler()}
            onItemClick={(val, valObj) => formVal.setValue(val, valObj)}
            error={formVal.error}
            selectListStyles={formVal.selectListStyles}
            tdLogSelectStyles={formVal.tdLogSelectStyles}
            tdLogSelectIconStyles={formVal.tdLogSelectIconStyles}
            freeSolo={formVal.freeSolo}
            // getLeftIcon={formItem.getLeftIcon}
          />
        );

      case "date":
        return (
          <>
            <NewDatePicker
              onChange={(newDate) => {
                formVal.setValue(newDate);
              }}
              date={formVal.value ? formVal.value : null}
              // value={formVal.value ? formVal.value : null}
              emptyLabel="custom label"
              error={formVal.error}
              rootStyle={`${isCollapsed ? "sca__cal-input" : "sca__cal-input2"}`}
              pickerStyles={formVal.pickerStyles}
              disabled={formVal.disabled}
              setFieldError={formVal.setFieldError}
              setFieldTouched={formVal.setFieldTouched}
              name={formVal.name}
              logDateInputStyles={formVal.logDateInputStyles}
              maxDate={formVal.maxDate ? formVal.maxDate : undefined}
              minDate={formVal.minDate ? formVal.minDate : undefined}
              required={formVal.required}
              dateInit={formVal.dateInit}
            />

            {formVal.error && <FormHelperText error={formVal.error}>{formVal.error}</FormHelperText>}
          </>
        );

      case "upload":
        return (
          <>
            <Dropzone maxFiles={1} onDrop={(files) => handleUpload(files[0], formVal)}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className={"container"}
                  style={{
                    padding: uploadingContent && !formVal.value ? "6.5px" : "0px",
                    display: "flex",
                    flex: 1,
                    border: formVal.error ? "1px solid red" : 0,
                    paddingLeft: "0",
                  }}
                >
                  <div
                    {...getRootProps({
                      className: "dropbox",
                      onDrop: (event) => event.stopPropagation(),
                    })}
                    style={{ display: "flex", flex: 1 }}
                  >
                    <input
                      {...getInputProps()}
                      disabled={formVal.disabled}
                      style={{ margin: "10px" }}
                      // onChange={(e) => handleUpload(e.target.files[0], formVal)}
                    />

                    <div className={formVal.value ? "db__upload bor" : "db__upload"}>
                      {!uploadingContent && <p>{formVal.value ? formVal.value : `Upload File`}</p>}
                      {uploadingContent && (
                        <div className={"progress"} style={{ position: "absolute" }}>
                          <CircularProgress
                            style={{
                              width: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </>
        );

      case "checkbox":
        return (
          <Checkbox
            color={"secondary"}
            disabled={formVal.disabled}
            onChange={(e) => {
              formVal.setValue(e.target.checked);
            }}
            checked={formVal.value ? formVal.value : false}
          />
        );
      default:
        return null;
    }
  };

  if (viewOnly) {
    return (
      <Grid container className={`db_section ${rootStyles}`}>
        <Grid item xs={12}>
          <Typography variant="h1">{title}</Typography>
          {titleRight}
        </Grid>

        <Grid item xs={12} container spacing={1}>
          {formList.map((formVal) => {
            const dims = formVal.dims || DEFAULT_DIMS;
            return (
              <Grid
                item
                {...dims}
                style={{ display: "flex", flexDirection: "column" }}
                className={`db__input-wrap ${formVal.inputStyles}`}
                key={formVal.label}
              >
                <Typography variant="subtitle1" style={{ marginBottom: "6px" }}>
                  {formVal.label}
                </Typography>

                <div className={`db__input-inner ${formVal.inputGreyStyles}`}>
                  <input
                    className="db__input-view"
                    type={formVal.type || "text"}
                    value={formVal.value}
                    title={formVal.value}
                    readOnly
                  />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={`db_section ${rootStyles}`}>
      {renderHeader ? (
        renderHeader
      ) : (
        <Grid item xs={12} style={{ display: "flex" }} justifyContent={"space-between"}>
          <Typography variant="h1">{title}</Typography>
          {titleRight}
        </Grid>
      )}

      <Grid item xs={12} className={`db__formInput ${inputContainerStyles}`}>
        <Grid container spacing={1}>
          {formList.map((formVal, index) => {
            const dims = formVal.dims || DEFAULT_DIMS;
            return formVal.type === "divider" ? (
              <Grid
                item
                xs={12}
                style={{ width: "100%", height: "1px", background: "#eee", marginBlock: "22px" }}
                key={index}
              ></Grid>
            ) : (
              <Grid
                item
                {...dims}
                style={{ display: "flex", flexDirection: "column" }}
                key={index}
                className={`db__input-wrap ${formVal.inputStyles}`}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Tooltip title={formVal.label || ""} placement="top" arrow>
                    <Typography variant="subtitle1" style={{ marginBottom: "6px", whiteSpace: "nowrap" }}>
                      {formVal.label}
                    </Typography>
                  </Tooltip>
                  {formVal.showInfo && (
                    <Tooltip
                      title={formVal.info}
                      placement="right"
                      arrow
                      classes={{
                        tooltip: "db__input-wrap-edit-label",
                        arrow: "db__input-wrap-edit-label",
                      }}
                    >
                      <MarkIcon style={{ marginLeft: "5px", marginTop: "1px" }} />
                    </Tooltip>
                  )}
                </div>
                <div className="db__input-inner">
                  {/* <div style={{ flexdirection: "column" }}> */}
                  {renderInputType(formVal)}
                  {/* </div> */}
                  {formVal.renderRight}
                </div>
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12} style={{ display: "flex" }}>
          {renderRight}
        </Grid>
      </Grid>
      {renderFooter}
    </Grid>
  );
};
