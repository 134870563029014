import Wind_Direction from "assets/icons/sensors/WindDirection.svg";
import TemperatureIcon from "assets/icons/sensors/Temperature.svg";
import VoltsIcon from "assets/icons/volts.svg";
import RelativeHumidityIcon from "assets/icons/relative_humidity.svg";
import PressureIcon from "assets/icons/sensors/Pressure.svg";
import AlertCircleIcon from "assets/icons/alert_circle.svg";
import wind_speed_logo from "assets/icons/sensors/WindSpeed.svg";
import LoggerIcon from "assets/icons/Logger-dark.svg";
import MaintenanceIcon from "assets/icons/MaintenanceMenu-Dark.svg";
import PyranometerIcon from "assets/icons/Pyronometer_Blue.svg";
import thermistorIcon from "assets/icons/thermistor.svg";
import rainGuageIcon from "assets/icons/water.svg";
import ammeterIcon from "assets/icons/ammeter.svg";
import LidarDarkIcon from "assets/icons/LidarDark.svg";
import mapLocationIcon from "assets/icons/map-location.svg";
import compassIcon from "assets/icons/compass.svg";
// The rank and icon for each sensor type
//  The label should incluce both the actual label and a compact form
let sensorInfo = {
  maintenance: {
    icon: MaintenanceIcon,
    rank: 0,
    label: "Maintenance",
    label_compact: "Maint.",
  },
  logger: {
    icon: LoggerIcon,
    rank: 1,
    label: "Logger",
    label_compact: "Logger",

  },
  pyranometer: {
    icon: PyranometerIcon,
    rank: 1.5,
    label: "Pyranometer",
    label_compact: "Pyr.",
  },
  lidar: {
    icon:  LidarDarkIcon,
    rank: 1.75,
    label: "Lidar",
    label_compact: "Lidar",
  },
  anemometer: {
    icon: wind_speed_logo,
    rank: 2,
    label: "Anemometer",
    label_compact: "Anemo.",
  },
  wind_vane: {
    icon: Wind_Direction,
    rank: 3,
    label: "Wind Vane",
    label_compact: "Vane",
  },
  thermometer: {
    icon: TemperatureIcon,
    rank: 4,
    label: "Thermometer",
    label_compact: "Temp.",
  },
  barometer: {
    icon: PressureIcon,
    rank: 5,
    label: "Barometer",
    label_compact: "Press.",
  },
  voltmeter: {
    icon: VoltsIcon,
    rank: 60,
    label: "Voltmeter",
    label_compact: "Volts",
  },
  hygrometer: {
    icon: RelativeHumidityIcon,
    rank: 7,
    label: "Rel. Humidity",
    label_compact: "RH",
  },

  thermistor_clean: {
    icon: thermistorIcon,
    rank: 15,
    label: "Thermistor",
    label_compact: "Therm.",
  },
  thermistor_soiled: {
    icon: thermistorIcon,
    rank: 15.5,
    label: "Thermistor",
    label_compact: "Therm.",
  },

  rain_gauge: {
    icon: rainGuageIcon,
    rank: 8,
    label: "Rain Guage",
    label_compact: "Rain.G",
  },
  ISC_soiled_ammeter: {
    icon: ammeterIcon,
    rank: 16.5,
    label: "ISC Soiled Current",
    label_compact: "ISC. So",
  },
  ISC_clean_ammeter: {
    icon: ammeterIcon,
    rank: 16,
    label: "ISC Clean Current",
    label_compact: "ISC. Cl",
  },

  // Lidar types
  gps: {
    icon: mapLocationIcon,
    rank: 20,
    label: "GPS",
    label_compact: "GPS",
  },
  compass: {
    icon: compassIcon,
    rank: 20,
    label: "Compass",
    label_compact: "Compass",
  },


};

let measurementInfo = {
  wind_speed: {
    icon: wind_speed_logo,
    rank: 1,
    label: "Wind Speed",
    label_compact: "Wspd",
  },
  wind_direction: {
    icon: Wind_Direction,
    rank: 2,
    label: "Wind Dir",
    label_compact: "WDir",
  },
  vertical_wind_speed: {
    icon: Wind_Direction,
    rank: 2.5,
    label: "Vert. Spd",
    label_compact: "WDir",
  },
  air_temperature: {
    icon: TemperatureIcon,
    rank: 3,
    label: "Amb. Temperature",
    label_compact: "Temp",
  },
  air_pressure: {
    icon: PressureIcon,
    rank: 4,
    label: "Amb. Pressure",
    label_compact: "Press",
  },
  voltage: {
    icon: VoltsIcon,
    rank: 5,
    label: "Voltage",
    label_compact: "Volts",
  },
  relative_humidity: {
    icon: RelativeHumidityIcon,
    rank: 6,
    label: "Rel. Humidity",
    label_compact: "RH",
  },
};

// Convert geographic orientation to degrees
export const degToCompass = (num) => {
  const val = Math.floor((num / 22.5) + .5);
  const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"]
  return val ? arr[(val % 16)] : ''
}

// Return sensor icon given measurement and sensor type
export const getSensorIcon = (sensorTypeId, measurementTypeId) => {
  if (sensorTypeId && (sensorTypeId in sensorInfo)) {
    return (sensorInfo[sensorTypeId]["icon"]);
  } else if (measurementTypeId && (measurementInfo[measurementTypeId])) {
    return (measurementInfo[measurementTypeId]["icon"]);
  } else {
    return AlertCircleIcon;
  }
  // return measurementTypeId in measurementInfo ? measurementInfo[measurementTypeId]["icon"] : AlertCircleIcon;
};

// Return sensor label to usse
export const getSensorLabel = (sensorTypeId, measurementTypeId) => {
  if (sensorTypeId && (sensorTypeId in sensorInfo)) {
    return (sensorInfo[sensorTypeId]["label_compact"]);
  } else if (measurementTypeId && (measurementInfo[measurementTypeId])) {
    return (measurementInfo[measurementTypeId]["label_compact"]);
  } else {
    return (measurementTypeId);
  }
  // return measurementTypeId in measurementInfo ? measurementInfo[measurementTypeId]["label"] : measurementTypeId;
};

// Return sensor label to use
export const getSensorRank = (sensorTypeId, measurementTypeId) => {
  if (sensorTypeId && (sensorTypeId in sensorInfo)) {
    return (sensorInfo[sensorTypeId]["rank"]);
  } else if (measurementTypeId && (measurementInfo[measurementTypeId])) {
    return (measurementInfo[measurementTypeId]["rank"]);
  } else {
    return (1000);
  }
  // return measurementTypeId in measurementInfo ? measurementInfo[measurementTypeId]["rank"] : 1000;
};

// Construct a measurement point label
export const makeMeasurementLabel = (sensor, measPoint) => {
  let measurementLabel = getSensorLabel(sensor['sensor_type_id'], measPoint["measurement_type_id"]);
  let measurementHeight = measPoint["height_m"];
  // let measurementOrientation = degToCompass(measPoint['orientation']);
  let measurementOrientation = measPoint['orientation'] >= 0 ? measPoint['orientation'] : 'Unk.' + -1 * measPoint['orientation'];
  // If measurement type is of wind_speed or wind_direction, add the height and orientation. Otherwise, just add  height
  let measurementTitle = measurementHeight >= 0 ? `${measurementLabel} at ${measurementHeight}m ` : `${measurementLabel} (Ht unknown) `;
  // if (measPoint['measurement_type_id'] === 'wind_speed' || measPoint['measurement_type_id'] === 'wind_direction') & 
  if 
    ( sensor['sensor_type_id'] === 'anemometer' || sensor['sensor_type_id'] === 'wind_vane' || sensor['sensor_type_id'] === 'pyranometer') {
    measurementTitle += ` (${measurementOrientation} deg)`;
  }
  

  return measurementTitle;
};
