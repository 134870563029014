import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";

import { ArrowWhiteUp, ArrowGrayDown, TickWhite, PlusIC, TrashRed } from "assets/new";
import { PlusGreenIcon } from "assets";
import { DetailsBox } from "component_tree/shared/DetailsBox";
import {
  UPDATE_MOUNTING_ARRANGEMENT,
  CREATE_MOUNTING_ARRANGEMENT,
  GET_TOWER_BY_ID,
  FETCH_MOUNTING_TYPE,
  DELETE_MOUNTING_ARRANGEMENT,
} from "services";
import { ButtonPrimary } from "component_tree/shared";
import { Checkbox, CircularProgress } from "@material-ui/core";
import "./MountingArrangement.css";
import { ErrorContext, RightSidebarContext } from "contexts/misc/context";
import { Spinner } from "component_tree/shared";
import { format } from "date-fns";
import { handleErrorFromGQL } from "utils";
import * as Yup from "yup";
import NotFound from "pages/NotF0und";
import MeasurementAccordion from "component_tree/shared/Measurement/MeasurementAccordion";
import { useConfirmationDialogue } from "contexts/misc/ConfirmationDialogueContext";

export const MountingArrangement = ({ show, mounting_arrangements, towerId, measurementPointId, showCheckbox, selectedMountingArrangementUuids, onCheckboxChange }) => {
  const [showForm, setShowForm] = useState(null);
  const { setErrors } = useContext(ErrorContext);
  const [isMoreOpened, setIsMoreOpened] = useState(false);
  let [mountingArrangementsList, setMountingArrangementsList] = useState(() => mounting_arrangements);
  const { data: mountingTypes, loading, error } = useQuery(FETCH_MOUNTING_TYPE);

  const [updateMountingArrangement] = useMutation(UPDATE_MOUNTING_ARRANGEMENT, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });
  const [createMountingArrangement] = useMutation(CREATE_MOUNTING_ARRANGEMENT, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [deleteMountingArrangement] = useMutation(DELETE_MOUNTING_ARRANGEMENT, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const { isCollapsed } = useContext(RightSidebarContext);
  const mountingTypesList = mountingTypes?.mounting_type;
  const [editing, setEditing] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [hide, setHide] = useState(false);

  const getValidationSchema = (ma) => {
    const schema = Yup.object().shape({
      [`height_m-${ma.uuid}`]: Yup.number()
        .required("Required")
        .positive("Require positive input")
        .typeError("Number required"),
      [`date_from-${ma.uuid}`]: Yup.date().required("Required"),
      [`date_to-${ma.uuid}`]: Yup.date().notRequired().nullable(),
      [`vane_dead_band_orientation_deg-${ma.uuid}`]: Yup.number()
        .min(0, "Min value is 0")
        .max(360, "Max value is 360")
        .notRequired()
        .nullable(),
      [`boom_orientation_deg-${ma.uuid}`]: Yup.number()
        .min(0, "Min value is 0")
        .max(360, "Max value is 360")
        .notRequired()
        .nullable(),
      [`tilt_angle_deg-${ma.uuid}`]: Yup.number()
        .min(-360, "Min value is -360")
        .max(360, "Max value is 360")
        .notRequired()
        .nullable(),
    });
    return schema;
  };

  const formDetails = (ma, props) => {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setFieldError,
      setFieldTouched,
      errors,
      touched,
      initialValues,
    } = props || {};
    return [
      {
        value: values[`height_m-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Deployed Height (m)",
        name: `height_m-${ma.uuid}`,
        clickHandler: (e) => handleBlur(e),
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
        error: errors[`height_m-${ma.uuid}`] && touched[`height_m-${ma.uuid}`] ? errors[`height_m-${ma.uuid}`] : "",
      },
      {
        value: values[`boom_orientation_deg-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        clickHandler: (e) => handleBlur(e),
        label: !isCollapsed ? "Boom Orientation" : "Deg",
        name: `boom_orientation_deg-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
        error:
          errors[`boom_orientation_deg-${ma.uuid}`] && touched[`boom_orientation_deg-${ma.uuid}`]
            ? errors[`boom_orientation_deg-${ma.uuid}`]
            : "",
      },
      {
        value: values[`mounting_type_id-${ma.uuid}`],
        setValue: (val) => {
          setFieldTouched(`mounting_type_id-${ma.uuid}`);
          setFieldValue(`mounting_type_id-${ma.uuid}`, val);
          setEditing(true);
          // handleSubmit();
        },
        clickHandler: (e) => handleBlur(e),
        label: "Mounting Type Id",
        name: `mounting_type_id-${ma.uuid}`,
        placeholder: "",
        tdLogSelectStyles: "log__select-input",
        tdLogSelectIconStyles: "log__select-input-icon",
        inputType: "select",
        options: mountingTypesList,
        error: errors[`mounting_type_id-${ma.uuid}`] && touched[`mounting_type_id-${ma.uuid}`],
      },
      {
        value: values[`vane_dead_band_orientation_deg-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          handleBlur(e);
          // handleSubmit();
        },
        label: !isCollapsed ? "Vane Dead Band" : "Dead Band",
        name: `vane_dead_band_orientation_deg-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
        error:
          errors[`vane_dead_band_orientation_deg-${ma.uuid}`] && touched[`vane_dead_band_orientation_deg-${ma.uuid}`]
            ? errors[`vane_dead_band_orientation_deg-${ma.uuid}`]
            : "",
      },
      {
        value: values[`tilt_angle_deg-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          handleBlur(e);
          // handleSubmit();
        },
        label: !isCollapsed ? "Tilt Angle" : "Titl Angle",
        name: `tilt_angle_deg-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
        error:
          errors[`tilt_angle_deg-${ma.uuid}`] && touched[`tilt_angle_deg-${ma.uuid}`]
            ? errors[`tilt_angle_deg-${ma.uuid}`]
            : "",
      },
      {},
      {
        value: values[`date_from-${ma.uuid}`],
        setValue: (val) => {
          setFieldValue(`date_from-${ma.uuid}`, val);
          setFieldTouched(`date_from-${ma.uuid}`);
          setTimeout(() => {
            setEditing(true);
            // handleSubmit();
          }, 200);
        },
        label: "Date From",
        name: `date_from-${ma.uuid}`,
        placeholder: "",
        inputType: "date",
        setFieldError: setFieldError,
        setFieldTouched: setFieldTouched,
        error: errors[`date_from-${ma.uuid}`] && touched[`date_from-${ma.uuid}`] ? errors[`date_from-${ma.uuid}`] : "",
        dateRootStyles: "ma__date-root-style",
        maxDate: values[`date_to-${ma.uuid}`] ? new Date(values[`date_to-${ma.uuid}`]) : undefined,
        required: true,
        dateInit: initialValues[`date_from-${ma.uuid}`],
      },
      {
        value: values[`date_to-${ma.uuid}`],
        setValue: (val) => {
          setFieldValue(`date_to-${ma.uuid}`, val);
          setTimeout(() => {
            setEditing(true);
            // handleSubmit();
          }, 200);
        },
        clickHandler: () => { },
        label: "Date To",
        name: `date_to-${ma.uuid}`,
        placeholder: "",
        inputType: "date",
        setFieldError: setFieldError,
        setFieldTouched: setFieldTouched,
        error: errors[`date_to-${ma.uuid}`] && touched[`date_to-${ma.uuid}`] ? errors[`date_to-${ma.uuid}`] : "",
        dateRootStyles: "ma__date-root-style",
        minDate: values[`date_from-${ma.uuid}`] ? new Date(values[`date_from-${ma.uuid}`]) : undefined,
        required: false,
        dateInit: initialValues[`date_to-${ma.uuid}`],
      },
      // {
      //   value: values[`orientation_reference_id-${ma.uuid}`],
      //   setValue: (val) => {
      //     setEditing(true);
      //     handleChange(val);
      //   },
      //   clickHandler: (e) => {
      //     // handleBlur(e);
      //     // handleSubmit();
      //   },
      //   label: isCollapsed ? "Ref. ID" : "Orientation Reference ID",
      //   name: `orientation_reference_id-${ma.uuid}`,
      //   placeholder: "",
      //   inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
      //   inputType: "input",
      // },
    ];
  };

  const additionalFormDetails = (
    ma,
    { values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, errors, touched }
  ) => [
      {
        value: values[`boom_oem-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Boom OEM",
        name: `boom_oem-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "input",
      },
      {
        value: values[`boom_model-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Boom Model",
        name: `boom_model-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "input",
      },
      {
        value: values[`boom_length_mm-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Boom Length",
        name: `boom_length_mm-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
      },
      {
        value: values[`boom_diameter_mm-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Boom Diameter",
        name: `boom_diameter_mm-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
      },
      {
        value: values[`distance_from_mast_to_sensor_mm-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Distance From Mast To Sensor",
        name: `distance_from_mast_to_sensor_mm-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
      },
      {
        value: values[`upstand_diameter_mm-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Upstand Diameter",
        name: `upstand_diameter_mm-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
      },
      {
        value: values[`upstand_height_mm-${ma.uuid}`],
        setValue: (val) => {
          setEditing(true);
          handleChange(val);
        },
        clickHandler: (e) => {
          // handleBlur(e);
          // handleSubmit();
        },
        label: "Upstand Height",
        name: `upstand_height_mm-${ma.uuid}`,
        placeholder: "",
        inputStyles: `sd__input-box ${showForm && "sd__input-half"}`,
        inputType: "customNum",
        numType: "decimal",
        type: "text",
      },
    ];

  useEffect(() => {
    if (mounting_arrangements !== mountingArrangementsList) {
      setMountingArrangementsList(mounting_arrangements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounting_arrangements]);

  const handleNewItem = () => {
    if (!mountingArrangementsList.find((config) => config.uuid === "newItem")) {
      const newList = {
        measurementPointId,
        uuid: "newItem",
        mounting_type_id: "",
        boom_orientation_deg: 0,
        // date_from: new Date().toISOString(),
        // date_to: new Date().toISOString(),
        orientation_reference_id: "",
        vane_dead_band_orientation_deg: 0,
        boom_oem: "",
        boom_model: "",
        boom_length_mm: 0,
        boom_diameter_mm: 0,
        distance_from_mast_to_sensor_mm: 0,
        upstand_diameter_mm: 0,
        upstand_height_mm: 0,
        tilt_angle_deg: 0,
        height_m: 0,
      };
      setMountingArrangementsList([...mountingArrangementsList, newList]);
      setShowForm({ newItem: true });
      setHide(true);
      setNewItem(true);
    }
  };

  const handleDeleteMountingArrangment = async (mountingId) => {
    await deleteMountingArrangement({
      variables: {
        mountingArrangementId: mountingId,
      },
    }).then((res) => setHide(false));
  };

  const handleMountingArrangement = async (values, setSubmitting, maId) => {
    let data;
    if (maId !== "newItem") {
      data = await updateMountingArrangement({
        variables: {
          mountingArrangementId: maId,
          mounting_type_id: values[`mounting_type_id-${maId}`],
          boom_orientation_deg: values[`boom_orientation_deg-${maId}`],
          date_from: values[`date_from-${maId}`],
          date_to: values[`date_to-${maId}`],
          orientation_reference_id: values[`orientation_reference_id-${maId}`],
          vane_dead_band_orientation_deg: values[`vane_dead_band_orientation_deg-${maId}`],
          boom_oem: values[`boom_oem-${maId}`],
          boom_model: values[`boom_model-${maId}`],
          boom_length_mm: values[`boom_length_mm-${maId}`],
          boom_diameter_mm: values[`boom_diameter_mm-${maId}`],
          distance_from_mast_to_sensor_mm: values[`distance_from_mast_to_sensor_mm-${maId}`],
          upstand_diameter_mm: values[`upstand_diameter_mm-${maId}`],
          upstand_height_mm: values[`upstand_height_mm-${maId}`],
          tilt_angle_deg: values[`tilt_angle_deg-${maId}`],
          height_m: values[`height_m-${maId}`],
        },
      });
    } else {
      data = await createMountingArrangement({
        variables: {
          measurementPointId,
          mounting_type_id: values[`mounting_type_id-${maId}`],
          boom_orientation_deg: values[`boom_orientation_deg-${maId}`],
          date_from: values[`date_from-${maId}`],
          date_to: values[`date_to-${maId}`],
          orientation_reference_id: values[`orientation_reference_id-${maId}`],
          vane_dead_band_orientation_deg: values[`vane_dead_band_orientation_deg-${maId}`],
          boom_oem: values[`boom_oem-${maId}`],
          boom_model: values[`boom_model-${maId}`],
          boom_length_mm: values[`boom_length_mm-${maId}`],
          boom_diameter_mm: values[`boom_diameter_mm-${maId}`],
          distance_from_mast_to_sensor_mm: values[`distance_from_mast_to_sensor_mm-${maId}`],
          upstand_diameter_mm: values[`upstand_diameter_mm-${maId}`],
          upstand_height_mm: values[`upstand_height_mm-${maId}`],
          tilt_angle_deg: values[`tilt_angle_deg-${maId}`],
          height_m: values[`height_m-${maId}`],
        },
      });
      setMountingArrangementsList(mounting_arrangements);
    }
    if (data.errors) {
      //handleErrorFromGQL(data.errors, setErrors);
    }
    setHide(false);
    setEditing(false);
    setNewItem(false);
    setSubmitting(false);
  };
  
  const { confirm } = useConfirmationDialogue();
  const validateMountingArrangementForm = (values, configId) => {
    const errors = {};

    if (!values[`date_from-${configId}`]) {
      errors[`date_from-${configId}`] = "Date From Required";
    }

    return errors;
  };

  if (loading) {
    return (
      <article className="tm__box-center">
        <CircularProgress />
      </article>
    );
  }
  if (error) {
    return <NotFound />;
  }

  const getTitle = (data) => {
    let title = "Boom: ";
    // if (isCollapsed) {
    if (!(data?.boom_orientation_deg || data?.vane_dead_band_orientation_deg || data?.mounting_type_id)) {
      return title + "No Information";
    } else {
      let check = 0;
      if (data?.boom_orientation_deg) {
        title += data?.boom_orientation_deg + "deg ";
      }
      // if (!isCollapsed) {
      if (data?.mounting_type_id) {
        title += `( ${data?.mounting_type_id.replace("_", " ")} mount) `;
      }
      // }
      // if (!isCollapsed) {
      if (data?.vane_dead_band_orientation_deg) {
        title += "(Dead Band - " + data?.vane_dead_band_orientation_deg + "deg ) ";
      }
      // }
    }
    if (isCollapsed) {
      if (title.length > 20) {
        title = title.slice(0, 20) + "...";
      }
    }
    return title;
  };

  return (
    <article className="sc__body">
      <div className="sc__head" style={{ paddingBottom: "20px" }}>
        <h3>Mounting Arrangement</h3>
        {!hide && mountingArrangementsList.length > 0 && (
          <button onClick={handleNewItem}>
            <PlusGreenIcon />
            {show && <span>New arrangement</span>}
          </button>
        )}
      </div>

      {mountingArrangementsList
        .slice()
        // .sort((a, b) => a.uuid.localeCompare(b.uuid))
        .sort((a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime())
        .map((ma) => {
          return (
            <div key={ma.uuid} style={{display:'flex',alignItems:'center'}}>
            {showCheckbox && (
              <Checkbox
              style={{marginBottom:5}}
                checked={selectedMountingArrangementUuids.includes(ma.uuid)}
                onChange={() => onCheckboxChange(ma.uuid)}
              />
            )}
            <section
            style={{width:'100%'}}
              className={`ma__list-wrap ${showForm?.[ma.uuid] ? "ma__list-expand" : "ma__list-close"} ${isMoreOpened?.[ma.uuid] && "ma__list-expand-more"
                }`}
              key={ma.uuid}
            >
              <Formik
                initialValues={{
                  [`mounting_type_id-${ma.uuid}`]: ma.mounting_type_id || null,
                  [`boom_orientation_deg-${ma.uuid}`]: ma.boom_orientation_deg || null,
                  [`date_from-${ma.uuid}`]: ma.date_from || null,
                  [`date_to-${ma.uuid}`]: ma.date_to || null,
                  [`orientation_reference_id-${ma.uuid}`]: ma.orientation_reference_id || null,
                  [`vane_dead_band_orientation_deg-${ma.uuid}`]: ma.vane_dead_band_orientation_deg || null,
                  [`boom_oem-${ma.uuid}`]: ma.boom_oem || null,
                  [`boom_model-${ma.uuid}`]: ma.boom_model || null,
                  [`boom_length_mm-${ma.uuid}`]: ma.boom_length_mm || null,
                  [`boom_diameter_mm-${ma.uuid}`]: ma.boom_diameter_mm || null,
                  [`distance_from_mast_to_sensor_mm-${ma.uuid}`]: ma.distance_from_mast_to_sensor_mm || null,
                  [`upstand_diameter_mm-${ma.uuid}`]: ma.upstand_diameter_mm || null,
                  [`upstand_height_mm-${ma.uuid}`]: ma.upstand_height_mm || null,
                  [`tilt_angle_deg-${ma.uuid}`]: ma.tilt_angle_deg || null,
                  [`height_m-${ma.uuid}`]: ma.height_m || null,
                }}
                enableReinitialize={true}
                validate={(v) => validateMountingArrangementForm(v, ma.uuid)}
                onSubmit={(values, { setSubmitting }) => handleMountingArrangement(values, setSubmitting, ma?.uuid)}
                validationSchema={getValidationSchema(ma)}
              >
                {(formProps) => {
                  const heading = <>
                      <h3>{getTitle(ma)}</h3>
                        <aside>
                          {formProps.isSubmitting && (
                            <div className="sc__saved">
                              <TickWhite />
                              <h3>Saved</h3>
                            </div>
                          )}
                          {showForm?.[ma.uuid] ? <ArrowWhiteUp /> : <ArrowGrayDown />}
                        </aside>
                  </>
                  const handleAccordianClick = ()=>{
                    setShowForm({ [ma.uuid]: !showForm?.[ma.uuid] });
                  }
                  return (
                    <>
                      <MeasurementAccordion 
                      heading={heading}
                      active_bg_color="blue"
                      onClick={handleAccordianClick}
                      open={showForm?.[ma.uuid]}
                      >
                        {showForm?.[ma.uuid] && (
                        <form onSubmit={formProps.handleSubmit} key={ma.uuid} id={ma.uuid}>
                          {/* {isMoreOpened?.[ma.uuid] ? ( */}
                          <DetailsBox
                            renderHeader={<div />}
                            key={"basicDetails"}
                            rootStyles="sc__box"
                            inputContainerStyles={!isCollapsed ? "sc__collapsed" : " "}
                            renderRight={
                              !isMoreOpened?.[ma.uuid] ? (
                                <>
                                  {(editing || newItem) && (
                                    <footer className="ma__submit">
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <button
                                          type="submit"
                                          className="ma__submit-btn"
                                          disabled={formProps.isSubmitting}
                                        >
                                          {formProps.isSubmitting ? <Spinner color="white" /> : "Save"}
                                        </button>
                                        <button
                                          type="button"
                                          className="ma__submit-btn ma__submit-cancel"
                                          onClick={() => {
                                            formProps.resetForm();
                                            setEditing(false);
                                            setNewItem(false);
                                            let newList = mountingArrangementsList.filter((s) => s.uuid !== "newItem");
                                            setMountingArrangementsList(newList);
                                            setHide(false);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                      {ma.update_at && (
                                        <div className={"updated-at-text"}>
                                          Last Updated at : {format(new Date(ma.update_at), "dd MMMM yyyy")}
                                        </div>
                                      )}
                                    </footer>
                                  )}
                                </>
                              ) : null
                            }
                            formList={formDetails(ma, formProps)}
                          />
                          {isMoreOpened?.[ma.uuid] && (
                            <>
                              <hr />
                              <DetailsBox
                                renderHeader={<div />}
                                key={"basicDetails"}
                                rootStyles="sc__box"
                                inputContainerStyles={!isCollapsed ? "sc__collapsed" : " "}
                                renderRight={
                                  <>
                                    <ButtonPrimary
                                      renderLeft={<PlusIC style={{ marginRight: 10 }} />}
                                      btnStyle={{ maxWidth: "50%" }}
                                      nameValue={"Less Fields"}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsMoreOpened({ ...isMoreOpened, [ma.uuid]: !isMoreOpened[ma.uuid] });
                                      }}
                                    />
                                    {(editing || newItem) && (
                                      <footer className="ma__submit">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          <button
                                            type="submit"
                                            className="tm__submit-btn"
                                            disabled={formProps.isSubmitting}
                                          >
                                            {formProps.isSubmitting ? <Spinner color="white" /> : "Save"}
                                          </button>
                                          <button
                                            type="button"
                                            className="tm__submit-btn tm__submit-cancel"
                                            onClick={() => {
                                              setEditing(false);
                                              setNewItem(false);
                                              formProps.resetForm();
                                              let newList = mountingArrangementsList.filter(
                                                (s) => s.uuid !== "newItem"
                                              );
                                              setMountingArrangementsList(newList);
                                              setHide(false);
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        {ma.update_at && (
                                          <div className={"updated-at-text"}>
                                            Last Updated at : {format(new Date(ma.update_at), "dd MMMM yyyy")}
                                          </div>
                                        )}
                                      </footer>
                                    )}
                                  </>
                                }
                                formList={additionalFormDetails(ma, formProps)}
                              />
                            </>
                          )}
                        </form>
                      )}
                      </MeasurementAccordion>
                    </>
                  );
                }}
              </Formik>

              {!newItem && (
                <div className="ma__delete-btn-container">
                  <button
                    type="button"
                    className="ma__delete-btn"
                    onClick={(e) => {
                      confirm({
                        color: 'error',
                        title: `Delete Mounting Arrangement`,
                        acceptButtonText: 'Delete',
                        content:
                          `Are you sure you want to delete this Mounting Arrangement?`,
                        onClose: (isConfirmed) => {
                          if (isConfirmed) {
                            handleDeleteMountingArrangment(ma.uuid)
                          }
                        },
                      });
                    }}
                  >
                    <TrashRed /> <span> Delete Arrangement</span>
                  </button>
                </div>
              )}
            </section>
            </div>
          );
        })}

      {!hide && mountingArrangementsList.length === 0 && (
        <button onClick={handleNewItem} className="new-config-row">
          <PlusGreenIcon />
          {!hide && <span>New arrangement</span>}
        </button>
      )}
    </article>
  );
};
