import { useState, useContext, useEffect } from "react";
import { Link, useRouteMatch, useHistory, useParams, Redirect } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { Formik } from "formik";
import { ErrorContext } from "contexts/misc/context";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Snackbar, TextField, InputAdornment, Typography, Tooltip } from "@material-ui/core";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AppsIcon from '@material-ui/icons/Apps';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {
  WindSpeed,
  LoggerIcon,
  MastTower,
  CalibrationIcon,
  DataColumns,
  DataDocuments,
  MaintenanceMenu,
  SiteDetails,
  SensorData,
  ArrowUp,
  ArrowDown,
  ArrowBack,
  SearchIcon,
  AlertWhiteIcon,
  ImportantIcon,
  LidarIcon,
  SodarIcon,
} from "assets";
import { getIconPathForMsmtLocation, newLocationInitialValues, newLocationFormItems, handleErrorFromGQL, getStyledSideBarIconForPlant } from "utils";
import {
  CREATE_PLANT,
  CREATE_LOCATION,
  FETCH_PLANTS,
  GET_PLANT_BY_ID,
  FETCH_PLANT_TYPES,
  GET_TOWER_BY_ID,
  FETCH_LOCATION_TYPES,
  FETCH_RELEVANT_MEASUREMENT_APP_BY_LOCATION,
  FETCH_RELEVANT_MEASUREMENT_APP_RUN_BY_LOCATION,
} from "services";
import { ButtonAdd } from "component_tree/shared";
import {
  getIconPathForPlant,
  getSideBarIconForMsmtLocation,
  getSideBarIconForPlant,
  getSideBarIconForTowerMeasurement,
  newPlantFormItems,
  newPlantInitialValues,
} from "utils";
import { WhiteSettingIcon } from "assets";
import { CreateProjectModal } from "component_tree/shared";
import { CheckIcon, ErrorIcon } from "assets/new";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { useMemo } from "react";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sideBar: {
    display: "flex",
    flexDirection: "column",
    width: "25rem",
    backgroundColor: "var(--left-side-bar-bg-color)",
  },
  sideBarWrap: {
    display: "flex",
    marginBottom: "15px",
    flexDirection: "column",
    overflow: "auto",
  },
  sideHead: {
    display: "flex",
    flexDirection: "column",
    color: "var(--white)",
    padding: "0",
    "& a": {
      textDecoration: "none",
    },
    "& > li:not(:first-child)": {
      marginTop: "1.8rem",
    },
  },
  emptyText: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },

  titleWrap: {
    display: "flex",
    alignItems: "center",
    padding: "2rem",
    paddingLeft: "8px",
  },
  mainTitle: {
    textTransform: "capitalize",
    color: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // transition: "all .3s ease-in",
    paddingLeft: "2.8rem",
    opacity: ".8",
    "& svg": {
      marginRight: "1rem",
    },
    "&:hover , &.active": {
      opacity: 1,
    },
  },
  mainTitleSelected: {
    fontWeight: 500,
    color: "#fff",
    opacity: 1,
    borderBottom: "0.2px solid rgba(255, 255, 255, 0.1)",
    padding: "2rem 1.5rem 1.6rem",
    "& a": {
      opacity: 0.8,
    },
  },
  rootTitle: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "normal",
    color: "var(--white)",
    textTransform: "capitalize",
    marginLeft: "10px",
  },
  count: {
    fontStyle: "italic",
    fontSize: "10px",
    marginLeft: "5px",
    color: "rgb(255,255 ,255,  .5)",
  },
  a: {
    textDecoration: "none",
  },
  sideHead1: {
    margin: "15px 0px",
    color: "#fff",
    paddingLeft: "0",
    "& a": {
      textDecoration: "none",
    },
    "& > li:not(:first-child)": {
      // marginTop: '1.4rem',
    },
  },
  titleWrap1: {
    display: "flex",
    fontFamily: "Roboto",
    lineHeight: "1.5",
    color: "#fff",
    fontWeight: theme.spacing(60),
  },
  mainTitle1: {
    position: "relative",
    marginTop: "2rem",
    marginBottom: "1.3rem",
    marginRight: "2rem",
    marginLeft: "2rem",
    fontSize: "15px",
    color: "#ffff",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    display: "flex",
    alignItems: "center",
    transition: "all .3s ease-in",
    "&:hover , &.active": {
      color: "#fff",
    },
    "& svg": {
      marginRight: "1rem",
      borderRadius: "2px",
    },
  },
  settingIcon: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "0",
    },
  },
  subTitle: {
    fontFamily: "Roboto",
    position: "relative",
    fontSize: "15px",
    color: "var(--icon-white)",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "7px 28px",
    transition: "all .3s ease-in",
    opacity: "0.4",
    "& svg": {
      marginRight: ".7rem",
      width: "2rem",
      height: "2rem",
    },
    "&::before": {
      content: '" "',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "4px",
      transition: "all .3s ease-in",
      // borderLeft: '4px solid #00c48c',
    },
    "&.active": {
      marginBottom: "1.5rem",
    },
    "&:hover , &.active": {
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
      "&::before": {
        backgroundColor: "#00c48c",
      },
      backgroundColor: "rgb(255,255 ,255,  .04)",
    },
  },
  arrowIcon: {
    width: "5.42px",
    height: "10px",
  },
  projectTitle: {},
  subList: {
    // marginTop: "1.5rem",
    // marginBottom: "2rem",
    // margin: "1.8rem 0",
    display: "block",
    // paddingLeft: "5rem",
    // paddingLeft: "4.2rem",
    padding:0,
    "& li:not(:first-child)": {
      // marginTop: "5px",
    },
    "& li": {
      paddingLeft: "35px",
      display:'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: "2.4rem",
      cursor: "pointer",
      padding: "6px 0px",
      "& .subItem": {
        fontFamily: "Roboto",
        color: "var(--white)",
        fontSize: "14px",
        letterSpacing: "0.2px",
        opacity: "0.7",
        lineHeight: "2.4rem",
        display: "flex",
        alignItems: "center",
        transition: "all .3s ease",
        "& svg": {
          marginRight: ".7rem",
          width: "18px",
          height: "18px",
        },
        "&:hover,&.active": {
          opacity: "1",
          color: theme.palette.secondary.main,
          "& svg": {
            "& path": {
              fill: theme.palette.secondary.main,
            },
            "& circle": {
              fill: theme.palette.secondary.main,
            },
            "& ellipse": {
              fill: theme.palette.secondary.main,
            },
            "& rect": {
              fill: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  },
  listHeading:{
    fontWeight:500,
    fontSize:15,
    color: '#707683',
    margin:'6px 0px !important',
  },
  subOfSubItem: {
    fontFamily: "Roboto",
    color: "var(--white)",
    fontSize: "14px",
    letterSpacing: "0.2px",
    opacity: "0.7",
    lineHeight: "2.4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "all .3s ease",
    paddingRight: "1.5rem",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: ".7rem",
      },
    },
    "&:hover,&.active": {
      opacity: 1,
      "& svg": {
        opacity: 1,
      },
    },
  },
  subOfSubItemList: {
    listStyleType: "none",
    paddingLeft: "15px",
    margin: "15px 0px 15px 25px",
    borderLeft: "0.5px solid rgba(255, 255, 255, 0.1)",
    "& .subOfSubItemListItem": {
      fontFamily: "Roboto",
      color: "var(--white)",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "0.2px",
      opacity: "0.6",
      lineHeight: "2.4rem",
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: ".8rem",
      },
      "&:hover,&.active": {
        opacity: 1,
        color: theme.palette.secondary.main,
        "& svg": {
          "& path": {
            fill: theme.palette.secondary.main,
          },
          "& circle": {
            fill: theme.palette.secondary.main,
          },
          "& ellipse": {
            fill: theme.palette.secondary.main,
          },
          "& rect": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
  },
  towerSubList: {
    padding: "0",
    marginTop: "1.2rem",
    marginBottom: "2rem",
    "& li:not(:first-child)": {
      marginTop: ".5rem",
    },
  },
  towerSubItem: {
    color: "#fff",
    opacity: "0.56",
    lineHeight: "1.85",
    letterSpacing: "0.5px",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "7px",
      width: 20,
      height: 20,
    },
    "&:hover": {
      opacity: "1",
    },
  },
  boxCenter: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  searchContainer: {
    padding: "2rem",
    position: "relative",
    borderBottom: "0.5px solid rgba(255, 255, 255, 0.1)",
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
  },
  searchInput: {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    borderRadius: "2px",
    "& input": {
      padding: "6.7px 10px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      color: "rgba(255, 255, 255, 0.4)",
      letterSpacing: "0.04em",
    },
  },
  andrment: {
    paddingRight: "10px",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#0E1625',
  },
  tooltip: {
    backgroundColor: '#0E1625',
    maxWidth: 180,
    marginLeft:20,
    padding:8,
    fontSize:11
  },
}));

function CustomTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

const IframeComponent = ({ projectId, towerId, property }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const plottingArgs = useMemo(() => {
    if (projectId && towerId) {
      return `project_id=${projectId}&tower_id=${towerId}`;
    }
    return "";
  }, [projectId, towerId]);

  useEffect(() => {
    if (property === 'plots' && !isLoaded) {
      setIsLoaded(true);
    }
  }, [property, isLoaded]);

  return isLoaded ? (
    <div
      style={{
        display: property === 'plots'?'block':'none', 
        height: "100%",
        width: "calc(100% - 25rem)",
        background: "white",
        position: "absolute",
        right: "0px",
        bottom: "0px",
        zIndex: "10",
      }}
    >
      <iframe
        src={`${process.env.REACT_APP_BASE_URL || ''}/view_data?${plottingArgs}`}
        style={{ width: "100%", height: "100%" }}
        title="Nodle App"
      />
    </div>
  ) : null;
};


export const SideBar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [createToast, setCreateToast] = useState("");
  const [center, setCenter] = useState(null);
  const [locationCenter, setLocationCenter] = useState(null);
  const [FilterText, setFilterText] = useState("");
  const [isDataAndDocuments, setIsDataAndDocuments] = useState(false);
  const [isAppsActive, setIsAppsActive] = useState(false);
  const [activeApp, setActiveApp] = useState(false);
  const [appsCollapsed, setAppsCollapsed] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [FilteredData, setFilteredData] = useState([]);
  const { setErrors } = useContext(ErrorContext);

  const navigateBack = () => {
    history.goBack();
  };
  const openSettings = (e, projId) => {
    // e.stopPropogation();
    e.preventDefault();
    history.replace(`/${projId}/settings`);
  };

  const classes = useStyles();
  const match = useRouteMatch([
    "/view_data",
    "/alerts",
    "/create",
    "/nodles",
    // "/alerts",
    "/:projectId/:towerId/tower/:property",
    "/:projectId/map-view",
    "/:projectId/site_details",
    "/:projectId/create",
    "/:projectId/settings",
    "/:projectId/export-data",
    "/:projectId/:towerId",
    "/:projectId",
  ]);

  let projectId = match?.params?.projectId || "";
  let towerId = match?.params?.towerId || "";
  let property = match?.params?.property || "";

  const [refetchAllPlants, { loading, error, data }] = useLazyQuery(FETCH_PLANTS);
  const plantsList = data?.plant;


  const { loading: loadingPlantList, error: errorPlantList, data: plantTypes } = useQuery(FETCH_PLANT_TYPES);
  const [refetchPlant, { data: plantData }] = useLazyQuery(GET_PLANT_BY_ID, { variables: { plantId: projectId } });
  const { data: locationTypes } = useQuery(FETCH_LOCATION_TYPES);
  const [createPlantDetails] = useMutation(CREATE_PLANT, {
    errorPolicy: "all",
    refetchQueries: [{ query: FETCH_PLANTS }],
  });
  const [createLocationDetails] = useMutation(CREATE_LOCATION, {
    errorPolicy: "all",
    refetchQueries: [{ query: FETCH_PLANTS }, { query: GET_PLANT_BY_ID, variables: { plantId: projectId } }],
  });

  const {
    data: towerData,
    loading: towerLoading,
    error: towerError,
    refetch: refetchTower,
  } = useQuery(GET_TOWER_BY_ID, {
    skip: !towerId,
    variables: { towerId: towerId },
  });

  const {data:app} = useQuery(FETCH_RELEVANT_MEASUREMENT_APP_BY_LOCATION, {
    variables: { measurement_location_uuid_with_per: "%"+ towerId +"%" }
  })

  const apps = app?.published_app_latest_versions
  ;

  const filteredData = apps;

  const locationTypeList = locationTypes?.measurement_station_type;
  const plantDetails = plantData?.plant?.[0];
  const projectTowerList = plantDetails?.measurement_locations || [];
  const towerCoordinates = projectTowerList?.reduce((agg, towerData) => {
    return [
      ...agg,
      {
        markerIcon: getIconPathForMsmtLocation(towerData),
        coordinates: { lat: towerData.latitude_ddeg, lng: towerData.longitude_ddeg },
        name: towerData.name,
        type: towerData.plant_type_id,
      },
    ];
  }, []);
  useEffect(() => {
    if (plantDetails) {
      setLocationCenter({ lat: plantDetails.latitude_ddeg, lng: plantDetails.longitude_ddeg });
    }
  }, [plantDetails]);

  useEffect(() => {
    // if (projectId && towerId) {
    refetchAllPlants()?.then(() => { });
    if (towerId) {
      refetchTower()?.then(() => { });
    }
  }, [towerId]);

  useEffect(() => {
    if (projectId && window.location.pathname === "/") {
      refetchPlant();
    }
  }, [projectId]);

  useEffect(() => {
    if (plantsList) {

      setFilteredData(plantsList.filter((item) => {
        return ((item.name.toLowerCase()).includes(FilterText.toLowerCase()));
      }))

    }
    else { }
  }, [plantsList, FilterText])

  const plantTypeList = plantTypes?.plant_type;
  const plantsCoordinates = plantsList?.reduce((agg, curr) => {
    return [
      ...agg,
      {
        markerIcon: getIconPathForPlant(curr),
        coordinates: { lat: curr.latitude_ddeg, lng: curr.longitude_ddeg },
        name: curr.name,
        type: curr.plant_type_id,
      },
    ];
  }, []);

  const handleClickBnt = () => {
    if (projectId) {
      history.push(`/${projectId}/create`);
    } else {
      history.push(`/create`);
    }
  };

  const handleModalClose = (props) => {
    if (projectId) {
      if (locationModalOpen) {
        props.resetForm();
        setLocationCenter(null);
      }
      setLocationModalOpen(!locationModalOpen);
    } else {
      if (modalOpen) {
        props.resetForm();
        setCenter(null);
      }
      setModalOpen(!modalOpen);
    }
  };
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const data = await createPlantDetails({
      variables: {
        plantName: values["projectName"],
        notes: values["notes"],
        longitude: values["longitude"],
        latitude: values["latitude"],
        plantTypeId: values["plantType"],
      },
    });

    const uuid = data.data?.insert_plant?.returning[0]?.uuid;
    history.push(`/${uuid}`);

    setSubmitting(false);
    setModalOpen(false);
    setCreateToast({ type: "success", message: "Plant Created Successfully" });
    resetForm();
    setCenter(null);
  };

  const handleLocationSubmit = async (values, setSubmitting, resetForm) => {
    const data = await createLocationDetails({
      variables: {
        plantId: projectId,
        locationName: values["locationName"],
        notes: values["notes"],
        longitude: values["longitude"],
        latitude: values["latitude"],
        locationTypeId: values["locationType"],
      },
    });

    if (data.errors) {
      //handleErrorFromGQL(data.errors, setErrors);
    } else {
      const uuid = data.data?.insert_measurement_location?.returning[0]?.uuid;
      if (uuid) {
        history.push(`/${projectId}/${uuid}`);
      }
    }

    setSubmitting(false);
    setLocationModalOpen(false);
    setCreateToast({ type: "success", message: "Plant Location Created Successfully" });
    resetForm();
    setLocationCenter(null);
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.projectName) {
      errors.projectName = "Project Name Required";
    }
    if (!values.longitude) {
      errors.longitude = "Longitude Required";
    }
    if (!values.latitude) {
      errors.latitude = "Latitude Required";
    }
    if (!values.plantType) {
      errors.plantType = "Plant Type Required";
    }

    return errors;
  };

  const validateLocationForm = (values) => {
    const errors = {};

    if (!values.locationName) {
      errors.locationName = "Location Name Required";
    }
    if (!values.longitude) {
      errors.longitude = "Longitude Required";
    }
    if (!values.latitude) {
      errors.latitude = "Latitude Required";
    }
    if (!values.locationType) {
      errors.locationType = "Location Type Required";
    }

    return errors;
  };

  if (loading || towerLoading || loadingPlantList) {
    return (
      <div className={classes.sideBar}>
        <div className={classes.boxCenter}>
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      </div>
    );
  }
  if (error || towerError || errorPlantList) {
    return (
      <div className={classes.sideBar}>
        <div className={classes.boxCenter}>
          <p style={{ color: "#fff" }}>Error :(</p>
        </div>
      </div>
    );
  }

  const CollapseHandler = () => {
    setCollapse(collapse ? false : true);
    // console.log("Clicked")
  }

  const SearchHandler = (e) => {
    setFilterText(e.target.value);

  }

  return (<>
    <div className={classes.sideBar}>
      <IframeComponent projectId={projectId} towerId={towerId} property={property} key={towerId}/>

      <div className={classes.sideBarWrap}>
        {!projectId && plantsList && (
          <>
            <div className={classes.searchContainer}>
              <TextField
                variant={"filled"}
                placeholder={"Search Projects"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  classes: { adornedEnd: classes.andrment },
                }}
                classes={{
                  root: classes.searchInput,
                }}
                value={FilterText}
                onChange={SearchHandler}
              />
            </div>
            <div className={classes.titleWrap} onClick={CollapseHandler} >

              <span>
                <Typography
                  variant="h2"
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "10px",
                  }}
                >
                  {collapse ? <KeyboardArrowDown style={{
                    marginBottom: "-4px",
                    fontSize: "20px",
                    marginRight: "6px",
                  }} /> : <KeyboardArrowRight style={{
                    marginBottom: "-4px",
                    fontSize: "20px",
                    marginRight: "6px",
                  }} />}
                  All Projects
                </Typography>{" "}
              </span>
              {plantsList &&
                <span className={classes.count}>{`(${plantsList.length})`}</span>
              }


            </div>
          </>
        )}

        {/*  Plants List */}

        {FilteredData.length && collapse &&
          <ul className={classes.sideHead}>
            {plantsList?.length === 0 && (
              <p className={classes.emptyText}>You currently have no projects added. Start by creating a project.</p>
            )}

            {orderBy(FilteredData, ['name'], ['asc']).map((plant) => {
              const plantSelected = projectId === plant.uuid;

              if (projectId && !plantSelected) return null;

              return (
                <li key={plant.uuid}>
                  <Link to={`/${plant.uuid}`}>
                    <div className={`${classes.mainTitle} ${plantSelected ? classes.mainTitleSelected : null}`}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {plantSelected && (
                          <ArrowBack
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push("/");
                            }}
                          />
                        )}
                        {getStyledSideBarIconForPlant(plant?.plant_type_id)}
                        <Typography variant="body2">{plant.name}</Typography>
                      </div>
                      {plantSelected && (
                        <div className={classes.settingIcon}>
                          <WhiteSettingIcon onClick={(e) => openSettings(e, projectId)} />
                        </div>
                      )}
                    </div>
                  </Link>

                  {/*  Tower List */}
                  {plantSelected && (
                    <ul className={classes.sideHead1}>
                      {orderBy(plant.measurement_locations, ['name'], ['asc']).map((measurementLocation, index) => {
                        const isTowerActive = towerId === measurementLocation?.uuid;
                        const isNotNewlyCreated = !Boolean(measurementLocation.is_new_created);
                        if(!isTowerActive && towerId)return null;
                        return (
                          <li key={index}>
                            {/* <ul> */}
                            <Link to={`/${projectId}/${measurementLocation?.uuid}`}>
                              <div className={`${classes.subTitle} ${isTowerActive ? "active" : ""}`}>
                                {getSideBarIconForMsmtLocation(measurementLocation?.measurement_station_type_id)}
                                <Tooltip title={measurementLocation?.name} arrow>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {measurementLocation?.name}
                                  </Typography>
                                </Tooltip>
                              </div>
                            </Link>
                            {/* </ul> */}

                            {/*  Tower Selected List */}
                            {isTowerActive ? (
                              <ul className={classes.subList} style={{ display: isNotNewlyCreated ? "block" : "none" }}>
                                <li className={classes.listHeading} style={{margin:0}}>UPLOAD DATA</li>
                                <li style={{background:property === "sensor-data"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/sensor-data`}>
                                        <div
                                          className={`subItem`}
                                        >
                                          <SensorData />
                                          <Typography variant="body2">Sensor Data</Typography> 
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <CheckOutlinedIcon sx={{mr:2,height:18,width:18,color:'#00C48C'}}/>
                                      </CustomTooltip> */}
                                </li>

                                <li style={{background:property === "maintenance"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/maintenance`}>
                                        <div
                                          className={`subItem`}
                                        >
                                          <MaintenanceMenu />
                                          <Typography variant="body2">Maintenance</Typography>
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#F79009'}}/>
                                      </CustomTooltip> */}
                                    </li>
                                    <li style={{background:property === "calibration"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/calibration`}>
                                        <div
                                          className={`subItem`}
                                        >
                                          <CalibrationIcon />
                                          <Typography variant="body2">Calibration</Typography>
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#F79009'}}/>
                                      </CustomTooltip> */}
                                    </li>

                                    <li style={{background:property === "data_health_status"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/data_health_status`}>
                                        <div
                                          className={`subItem`}
                                        >
                                           <HealthAndSafetyIcon fontSize={"medium"}/>
                                          <Typography variant="body2">Data Health Status</Typography>
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#F79009'}}/>
                                      </CustomTooltip> */}
                                    </li>
                                    
                           <li className={classes.listHeading}>DATA DETAILS</li>

                                <li style={{background:property === "site_details"?'rgba(0, 204, 140, 0.3)':''}}>
                                  <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/site_details`}>
                                    <div className={`subItem`}>
                                      <SiteDetails />
                                      <Typography variant="body2">Site</Typography>
                                    </div>
                                  </Link>
                                  {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                  <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#F79009'}}/>
                                  </CustomTooltip> */}
                                </li>

                                  <>
                                    <li style={{background:property === "tower-properties"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/tower-properties`}>
                                        <div className={`subItem`}>
                                          <MastTower />
                                          <Typography variant="body2">Tower</Typography>
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#F79009'}}/>
                                      </CustomTooltip> */}
                                    </li>
                                    <li style={{background:property === "logger"?'rgba(0, 204, 140, 0.3)':''}}>
                                      <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/logger`}>
                                        <div className={`subItem `}>
                                          <LoggerIcon />
                                          <Typography variant="body2">Logger</Typography>
                                        </div>
                                      </Link>
                                      {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                      <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#E91C24'}}/>
                                      </CustomTooltip> */}
                                    </li>
                                  </>
                                {measurementLocation?.measurement_station_type_id === "lidar" && (
                                  <li style={{background:property === "lidar"?'rgba(0, 204, 140, 0.3)':''}}>
                                    <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/lidar`}>
                                      <div className={`subItem `}>
                                        <LidarIcon />
                                        <Typography variant="body2">Profiler</Typography>
                                      </div>
                                    </Link>
                                  </li>
                                )}
                                {measurementLocation?.measurement_station_type_id === "sodar" && (
                                  <li style={{background:property === "sodar"?'rgba(0, 204, 140, 0.3)':''}}>
                                    <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/sodar`}>
                                      <div className={`subItem `}>
                                        <SodarIcon />
                                        <Typography variant="body2">Profiler</Typography>
                                      </div>
                                    </Link>
                                  </li>
                                )}

                                <li style={{background:property === "measurement"?'rgba(0, 204, 140, 0.3)':''}}>
                                  <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/measurement`}>
                                    <div className={`subItem`}>
                                      <WindSpeed />
                                      <Typography variant="body2">Measurements</Typography>
                                    </div>
                                  </Link>
                                  {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                  <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#E91C24'}}/>
                                  </CustomTooltip> */}
                                </li>
                               
                                <li style={{background:property === "data_columns"?'rgba(0, 204, 140, 0.3)':''}}>
                                  <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/data_columns`}>
                                    <div className={`subItem`}>
                                      <DataColumns />
                                      <Typography variant="body2">Data Columns</Typography>
                                    </div>
                                  </Link>
                                  {/* <CustomTooltip title="The step is empty, please fill in the information here" arrow placement="right">
                                  <InfoOutlinedIcon sx={{mr:2,height:18,width:18,color:'#E91C24'}}/>
                                  </CustomTooltip> */}
                                </li>
                      
                           <li className={classes.listHeading}>ANALYZE & EXPORT</li>

                              <li onClick={() => {setAppsCollapsed(!appsCollapsed);setIsAppsActive(!isAppsActive)}}>
                                  <div className={`${classes.subOfSubItem} ${isAppsActive && 'active'}`} style={{display:'flex', justifyContent:'space-between',width:'100%',marginRight:3}}>
                                    <div>
                                    <AppsIcon />
                                      <Typography variant="body2">Apps</Typography>
                                    </div>
                                    {appsCollapsed ? <ArrowUp /> : <ArrowDown />}
                                  </div>
                                </li>

                                {appsCollapsed && (
                                  <ul className={classes.subOfSubItemList}>
                                  {filteredData?.map((app, index)=>{
                                    return(
                                         <li key={app.uuid} onClick={()=>{ setActiveApp(index)}}>
                                          <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/apps/${app.uuid}`}>
                                            <div
                                              className={`subOfSubItemListItem ${index === activeApp && "active"}`}
                                            >
                                              <Typography variant="body2">{app.name}</Typography>
                                            </div>
                                          </Link>
                                        </li>
                                    )
                                  })}
                                  </ul>
                                )}

                                <li>
                                  <Link to={`/${projectId}/${measurementLocation?.uuid}/tower/plots`}>
                                    <div className={`subItem ${property === "plots" ? "active" : ""}`}>
                                      <EqualizerIcon />
                                      <Typography variant="body2">Plots</Typography>
                                    </div>
                                  </Link>
                                </li>

                              </ul>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        }
      </div>
      {!(projectId && towerId) && <ButtonAdd btnTitle={projectId ? "Add new location" : "New project "} onClick={handleClickBnt} />}

      <Formik
        initialValues={projectId ? newLocationInitialValues : newPlantInitialValues}
        validate={projectId ? validateLocationForm : validateForm}
        enableReinitialize={projectId ? true : null}
        validateOnChange={projectId ? false : null}
        validateOnBlur={projectId ? true : null}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (projectId) {
            handleLocationSubmit(values, setSubmitting, resetForm);
          } else {
            handleSubmit(values, setSubmitting, resetForm);
          }
        }}
      >
        {(props) => (
          <CreateProjectModal
            modalType={plantsList?.length === 0 ? "newProject" : "projectDetails"}
            modalOpen={projectId ? locationModalOpen : modalOpen}
            handleOnClose={() => handleModalClose(props)}
            title={projectId ? "Add New Location" : "Create New Project"}
            formProps={props}
            buttonLabel={projectId ? "Create Location" : "Create Project"}
            onMapClick={(location, map) => {
              const { setFieldValue, setFieldTouched } = props;

              setFieldValue("latitude", location.lat());
              setFieldValue("longitude", location.lng());
              setFieldTouched("latitude");
              setFieldTouched("longitude");
              if (projectId) {
                setLocationCenter(location);
              } else {
                setCenter(location);
              }
              map.panTo(location);
            }}
            center={projectId ? locationCenter : center}
            formItems={projectId ? newLocationFormItems(locationTypeList) : newPlantFormItems(plantTypeList)}
            mapZoom={4}
            locationsDataList={projectId ? towerCoordinates : plantsCoordinates}
          />
        )}
      </Formik>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={createToast?.message}
        autoHideDuration={3000}
        onClose={() => setCreateToast(null)}
        message={
          <div className="cp__snack--content">
            {createToast?.type === "success" ? <CheckIcon /> : <ErrorIcon />}
            <p>{createToast?.message}</p>
          </div>
        }
        key={createToast?.message}
        className={createToast?.type === "success" ? "cp__snack--success" : "cp__snack--error"}
      />
    </div >
    </>
  );
};
